import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import i18n from "@/libs/i18n"
import moment from "moment"
import router from "./router"
import store from "./store"
import App from "./App.vue"

// Global Components
import './global-components'

// 3rd party plugins
import "@axios"
import "@/libs/acl"
import "@/libs/portal-vue"
import "@/libs/clipboard"
import "@/libs/toastification"
import "@/libs/sweet-alerts"
import "@/libs/vue-select"
import "@/libs/tour"

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require("@core/assets/fonts/feather/iconfont.css") // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.prototype.$DateFormat = obj => {
  if (!obj) return ""
  return moment(obj).local().format("DD/MM/YYYY HH:mm:ss")
}

Vue.prototype.$VolWeightFlight = obj => (obj / 0.005).toFixed(2)

Vue.prototype.$FreightType = obj => {
  let result = ''
  if (obj === 'F') {
    result = 'Air Freight'
  } else if (obj === 'S') {
    result = 'Sea Freight'
  } else if (obj === 'C') {
    result = 'Courier'
  } else {
    result = ''
  }
  return result
}

Vue.prototype.$FreightState = obj => {
  let result = ''
  if (obj === 'D') {
    result = 'Plan'
  } else if (obj === 'P') {
    result = 'Processing'
  } else if (obj === 'T') {
    result = 'Transit'
  } else if (obj === 'H') {
    result = 'History'
  } else {
    result = ''
  }
  return result
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount("#app")
